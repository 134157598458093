<template>
    <base-card>
        <v-card-text>
            <div
                class="d-flex justify-space-between align-center flex-wrap m-3"
            >
                <div class="flex-1 d-flex align-center mr-4" :class="fixture.homeTeamId ? 'cursor-pointer' : ''" @click="fixture.homeTeamId ? goToProfile(fixture.homeTeamId) : null">
                    <v-img contain :max-width="($vuetify.breakpoint.xsOnly) ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.homeLogo}.WEBP`" :alt="fixture.homeTeam" class="img_border"></v-img>
                    <div class="flex-grow-1 ml-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.homeTeam }}
                        </p>
                    </div>
                </div>
                <div v-if="(!$vuetify.breakpoint.xsOnly)" class="flex-1 text-center">
                    <p class="font-weight-semi ma-0">
                            {{ fixture.date }}
                    </p>
                    <span class="text--disabled">{{ fixture.venue }}</span>
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            {{ fixture.time }}
                        </p>
                    </div>
                </div>
                <div class="flex-1 d-flex align-center" :class="fixture.awayTeamId ? 'cursor-pointer' : ''" @click="fixture.awayTeamId ? goToProfile(fixture.awayTeamId) : null">
                    <div class="flex-grow-1 text-right mr-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.awayTeam }}
                        </p>
                    </div>
                    <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.awayLogo}.WEBP`" :alt="fixture.awayTeam" class="img_border"></v-img>
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="flex-1 mt-4 text-center" style="min-width: 263px">
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            {{ fixture.time }}
                        </p>
                    </div>
                    <span class="text--disabled">{{ fixture.venue }}</span>
                </div>
            </div>
            <v-divider class="mt-5 mb-3" />
            <div class="d-flex flex-wrap" v-if="(!hasMadeSelection || editing) && getCompetitionPredictionsOpen">
                    <v-btn
                        v-if="footerBtnColor"
                        class="ma-2 mr-0"
                        :color="fixture.homeColour"
                        small
                        dark
                        @click="toggleExpand(1)"
                        :class="result == 1 ? 'active' : ''"
                    >
                        {{ fixture.homeTeam }}
                    </v-btn><v-spacer />
                    <v-btn
                        v-if="footerBtnColorTwo"
                        class="ma-2 ml-0 mr-0"
                        small
                        dark
                        @click="toggleExpand(-1)"
                        :class="result == -1 ? 'active' : ''"
                    >
                        Draw
                    </v-btn><v-spacer />
                    <v-btn
                        v-if="footerBtnColorTwo"
                        class="ma-2 ml-0"
                        :color="fixture.awayColour"
                        small
                        dark
                        @click="toggleExpand(2)"
                        :class="result == 2 ? 'active' : ''"
                    >
                        {{ fixture.awayTeam }}
                    </v-btn>
                <v-expand-transition>
                    <div v-show="!showScoreSelection" class="text-center" style="width: 100%">
                        <small v-if="this.result != -1">{{ resultText }}</small>
                        <p class="pb-2 mt-2" v-else>{{ resultText }}</p>
                    </div>
                </v-expand-transition>
                <v-expand-transition>
                    <div v-show="showScoreSelection" class="text-center" style="width: 100%">
                        <span v-for="button in getWinningMargins" :key="button.value">
                            <v-btn
                                v-if="button.value != 0"
                                class="ma-1"
                                depressed
                                :color="selectedColour"
                                dark
                                small
                                @click="winningMarginSelected(button)"
                                :class="margin == button.value ? 'active' : ''"
                            >
                                {{ button.label }}
                            </v-btn>
                        </span>
                    <div class="text-center" style="width: 100%">
                        <p class="pb-2 mt-2" v-if="this.margin">{{ selectionText }}</p>
                        <small v-else>{{ selectionText }}</small>
                    </div>
                    </div>
                </v-expand-transition>
            </div>
            <div v-else>
                <div class="flex-1 text-center text-subtitle-1" :style="predictionColor">{{ predictionText }}</div>
            </div>
        </v-card-text>
    </base-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        // Floating Button icon Color
        fixture: {
            type: Object,
            required: true
        },
        progressbarColor: {
            type: String,
            default: 'warning'
        },
        footerBtn: {
            type: String,
            default: 'Chat'
        },
        footerBtnTwo: {
            type: String,
            default: 'Profile'
        },
        footerBtnColor: {
            type: String,
            default: 'red'
        },
        footerBtnColorTwo: {
            type: String,
            default: 'blue'
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expand: false,
            result: 0,
            winner: null,
            margin: null,
            marginText: ''
        }
    },
    computed: {
        ...mapGetters(["getWinningMargins", "hasMadeSelection", "getGameWeekSelection", "getCompetitionPredictionsOpen"]),
        selectedColour() {
           return this.result == 2 ? this.fixture.awayColour : this.fixture.homeColour;
        },
        selectionText() {
            if(this.winner == 0){
                return "Draw Game";
            }
            else if(this.winner && this.margin){
                return `${this.winner} by ${this.marginText} points`;
            } else {
                return 'Choose margin of victory';
            }
            
        },
        resultText() {
            if(this.result == -1){
                return 'Draw Game'
            }
            else {
                return 'Make your prediction'
            }
        },
        showScoreSelection(){
            return this.expand && this.result != -1
        },
        predictionText(){
            let prediction = this.$store.getters['getGameWeekSelection'].find(selection => selection.fixtureEnt.id == this.fixture.id)
            if(!prediction) return 'No selection made'
            if(prediction.matchResult == 'HOME_WIN'){
                return this.fixture.homeTeam + ` ${prediction.winningMargin.label}`;
            } else if(prediction.matchResult == 'AWAY_WIN'){
                return this.fixture.awayTeam + ` ${prediction.winningMargin.label}`;;
            }
            return 'Draw';
        },
        predictionColor(){
            let prediction = this.$store.getters['getGameWeekSelection'].find(selection => selection.fixtureEnt.id == this.fixture.id)
            if (!prediction) return 'colour: grey';
            if(prediction.matchResult == 'HOME_WIN'){
                return `color: ${this.fixture.homeColour}`;
            } else if(prediction.matchResult == 'AWAY_WIN'){
                return `color: ${this.fixture.awayColour}`;
            }
            return 'color: grey';
        }
    },
    methods: {
        ...mapActions(["makeSelection"]),
        goToProfile(id){
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        },
        toggleExpand(result) {
            if(this.result == result){
                this.expand = !this.expand;
                this.result = 0;
                this.winner = null;
                this.margin = null;
                this.$emit('marginUnSelected')
            } else {
                this.expand = true;
                this.result = result;
                if(result != -1){
                    if(this.margin){
                        this.winningMarginSelected(this.margin);
                    }
                    this.winner = result == 1 ? this.fixture.homeTeam : this.fixture.awayTeam;
                } else {
                    this.winner = 0;
                    this.result = result;
                    this.winningMarginSelected(0);
                }
            }
        },
        winningMarginSelected(margin){
            if(this.margin === margin.value){
                this.margin = null;
                this.marginText = '';
                this.$emit('marginUnSelected');
            } else {
                this.margin = margin.value;
                this.marginText = margin.label;
                this.makeSelection({"winner": this.winner, "margin": margin.value, "fixtureId" : this.fixture.id, "result": this.result});
                this.$emit('marginSelected', margin.value);
            }
        }
    }
}
</script>

<style scoped>
 .active {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 9px 1px #9ecaed;
    /* box-shadow: 0 0 6px 3px #44a0ea; */
 }

.img_border {
    border-style: solid;
    border-width: 2px;
}
</style>