<template>
    <base-card class="h-full">
        <v-card-title v-if="title" class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
                <p class="card-title mb-0">{{ title }}
                    <div v-if="$vuetify.breakpoint.xsOnly" class="text-caption">{{ subtitle }}</div>
                    <span v-else class="text-caption">{{ subtitle }}</span>
                </p>
            </div>
            <v-menu left bottom v-if="getCompetitionPredictionsOpen">
                <template v-slot:activator="{on, attrs}">
                    <v-btn
                        color="secondary"
                        small
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        v-if="buttonText"
                    >
                        {{ buttonText }}
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list style="cursor: pointer;">
                    <v-list-item @click="childFunctionOne">
                        <v-list-item-title>Edit Selections<v-icon small class="ml-2">mdi-pencil</v-icon></v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="childFunctionTwo" disabled>
                        <v-list-item-title>Lock Selections<v-icon small>mdi-lock-outline</v-icon></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card-title>
        <v-divider v-if="title"></v-divider>
        <v-card-text>
            <slot name="chart"> </slot>
        </v-card-text>
    </base-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: 'More'
        },
        childFunctionOne: Function,
        childFunctionTwo: Function
    },
    computed: {
        ...mapGetters(["getCompetitionPredictionsOpen"])
    }
}
</script>
